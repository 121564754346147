/*--------------------------------------------------------------
# Buttons
--------------------------------------------------------------*/
.button {
	background: rgba(0, 0, 0, 0.5);
	color: #fff;
	font-size: 16px;
	font-weight: $bold;
	padding:5px 15px;
	transition:all 0.3s ease;
	color: $color-white;
	text-decoration: none;
	
	.fa {
		margin-left: 5px;
	}

	&:hover {
		background: $color-primary;
	}
}