/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
.content {
	width: 100%;
	float: left;
	line-height: 26px;

	h1, h2, h3, h4, h5, h6 {
		width: 100%;
		float: left;
		font-size: 24px;
		margin:0px 0px 10px 0px;
		position: relative;

		.num {
			position: absolute;
			top: -0px;
		    right: 0px;
		    width: 40px;
		    height: 40px;
		    background: #2590c4;
		    color: #fff;
		    line-height: 38px;
		    text-align: center;
		    font-size: 24px;
		    font-weight: 700;
		    border-radius: 40px;
		}
	}

	a {
		color: $color-text;
		text-decoration: underline;
	}

	.inner {
		box-sizing: border-box;
		float: left;
		width: 100%;
		margin-bottom: 30px;
		position: relative;
	}

	.button {
		color: $color-white;
		text-decoration: none;
		margin-top: 20px;
		background:$color-text;
		float: left;

		&:hover {
			background: $color-primary;
		}
	}

	input, textarea {
		box-shadow: inset 1px 2px 2px 0px rgba(0, 0, 0, 0.1);
		border: 1px solid $color-gray;
		padding: 13px 10px;
	}

	&.review-single {
		padding-bottom: 50px;
		
		.stars {
			width: 100%;
			float: left;
			color: $color-stars;
			font-size: 16px;
			margin-bottom: 10px;
			margin-top: -5px;

			.fa {
				margin-right: 2px;
				float: left;
			}
		}
	}
}