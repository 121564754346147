/*--------------------------------------------------------------
# Sidebar style
--------------------------------------------------------------*/
.web-reviews {
	width: 100%;
	float: left;
	background: $color-gray-light;
	margin: 50px 0px 0px 0px;
	padding: 60px 0px;

	h3 {
		margin-bottom: 10px;
	}

	.review-bar {
		width: 100%;
		float: left;
		border: 1px solid $color-gray;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
		border-radius: 3px;
		background: #fff;
	}

	a:hover {
		background: $color-gray-light;
	}

	.item {
		width: 25%;
		float: left;
		padding: 20px 55px 20px 20px;
		position: relative;
		border-left: 1px solid $color-gray;
		color: $color-text;
		
		h4 {
			font-size: 18px;
			float: left;
			width: 100%;
			margin: 3px 0px 20px 0px;
			padding: 0px;
		}

		p {
			width: 100%;
			float: left;
			line-height: 21px;
			font-size: 14px;
		}

		.date {
			color: darken($color-gray, 25);
		}

		.num {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 40px;
			height: 40px;
			background: $color-primary;
			color: #fff;
			line-height: 38px;
			text-align: center;
			font-size: 24px;
			font-weight: bold;
			border-radius: 40px;
		}

		&.usps {
			padding-right: 20px;
			border-left: 0px;

			.rating {
				width: 100%;
				font-weight: bold;
				float: left;
				margin-bottom: 10px;
				font-size: 16px;

				span {
					color: $color-primary;
				}

				.stars {
					width: 100%;
					float: left;

					.fa {
						float: left;
						color: $color-stars;
						margin-right: 3px;
					}
				}
			}

			ul {
				list-style: none;
				width: 100%;
				float: left;
				padding: 0px;
				margin: 0px;

				li {
					margin-bottom: 5px;

					&.best {
						font-weight: $bold;
					}
				}

				.fa {
					color: $color-green;
					margin-right: 5px;
					margin-top: 2px;
					float: left;
					font-size: 18px;
				}
			}
		}
	}
}

@media(min-width: 777px){

}

/*--------------------------------------------------------------
# Responsive sidebar style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}