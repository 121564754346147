/*--------------------------------------------------------------
# Usps style
--------------------------------------------------------------*/
.usps_bar {
	width: 100%;
	float: left;
	border-bottom:1px solid $color-gray;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

	@media(max-width: 990px){
		margin-top: 105px !important;
	}

	@media(max-width: 744px){
		display: none;
	}

	ul {
		width: 100%;
		float: left;
		list-style: none;
		text-align: left;
		display: inline-block;
		padding: 10px 0px;
		margin: 0px;

		li {
			display: inline-block;
			margin:10px 19px;

			@media(max-width: 990px){
				margin:10px 12px !important;
			}

			&.rating {
				@media(max-width: 990px){
					display: none;
				}
				float: right;
				font-weight: bold;

				span {
					color: $color-primary;
				}

				.fa {
					@media(max-width: 1200px){
						display: none;
					}
					font-size: 16px;
					margin-right: -2px;
					color: $color-stars;
				}
			}

			.fa {
				font-size: 18px;
				color:$color-green;
			}
		}
	}
}

@media(max-width: 999px){
	.usp_box{
		width: 44% !important;
	}
}

@media(max-width: 550px){
	.usp_box{
		width: 100% !important;
	}
}

@media(max-width: 1200px){
	.usps_bar ul li.rating{
		display: none;
	}
}

@media(max-width: 992px){
	.usps_bar ul{
		display: none;
	}
}
/*--------------------------------------------------------------
# Responsive Usps style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}