/*--------------------------------------------------------------
# Sidebar style
--------------------------------------------------------------*/
.reviews {
	width: 100%;
	float: left;
	background: $color-gray-light;
	margin: 0px;
	padding: 60px 0px;

	h3 {
		width: 100%;
		float: left;
		margin-bottom: 10px;
	}

	.review {
		width: 100%;
		float: left;
		box-sizing: border-box;
		background: #fff;
		margin:0px;
		border:1px solid $color-gray;
		border-radius: 5px;
		color: $color-text;
		overflow: hidden;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

		.product-name {
			font-size: 16px;
			font-weight: bold;
			color: $color-primary;
		}

		.stars {
			width: 100%;
			float: left;
			color: $color-stars;
			margin-bottom: 10px;
		}

		.quote {
			width: 100%;
			float: left;
		}

		&.big {
			.quote {
				font-size: 21px;
				line-height: 24px;
			}

			.left {
				padding: 15px 15px 25px 15px;
				width: 100%;
				float: left;
				min-height: 278px;
				border-right: 1px solid $color-gray;
			}

			figure {
				width: 100%;
				float: left;
				height: 278px;
			}
		}

		&.small {
			padding: 15px;
			margin-bottom: 30px;
			.quote {
				font-size: 16px;
			}

			figure {
				border: 1px solid $color-gray;
				width: 100%;
				height: 90px;
			}
		}
	}
}

/*--------------------------------------------------------------
# Responsive sidebar style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}