/*--------------------------------------------------------------
# Home style
--------------------------------------------------------------*/
.content.home {
	padding: 60px 0px;
}

.usp_box {
	width: 100%;
	float: left;
	padding: 15px;
	box-sizing: border-box;
	border:1px solid $color-gray;
	border-bottom: 5px solid $color-primary;
	border-radius: 4px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

	.rating {
		width: 100%;
		border-bottom: 1px solid $color-gray;
		padding-bottom: 10px;
		margin-bottom: 10px;
		font-weight: bold;
		float: left;
		font-size: 16px;

		span {
			color: $color-primary;
		}

		.stars {
			width: 100%;
			float: left;

			.fa {
				float: left;
				color: $color-stars;
				margin-right: 3px;
			}
		}
	}

	ul {
		list-style: none;
		width: 100%;
		float: left;
		padding: 0px;
		margin: 0px;

		li {
			margin-bottom: 5px;

			&.best {
				font-weight: $bold;
			}
		}

		.fa {
			color: $color-green;
			margin-right: 5px;
			margin-top: 4px;
			float: left;
			font-size: 18px;
		}
	}
}

/*--------------------------------------------------------------
# Responsive Home style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}