/*--------------------------------------------------------------
# Header style
--------------------------------------------------------------*/
.topbar {
  width: 100%;
  background: $color-white;
  float: left;
  //padding:10px 0px 0px 0px;
  position: fixed;
  z-index: 1000;

  .container {
    padding: 7px 15px;
    position: relative;
  }

  .logo {
    font-size: 26px;
    margin: 0px 0px 10px 0px;
    float: left;

    img {
      height: 45px;
    }

    a {
      color: $color-text;

      span {
        color: $color-primary;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .search {
    float: right;
    margin-bottom: 5px;
    margin-right: -4px;
    margin-top: 7px;

    input {
      width: 283px;
      float: left;
      border: 1px solid #dedede;
      border-radius: 4px;
      height: 32px;
      line-height: 40px;
      padding: 0px 21px 0 11px;
      box-sizing: border-box;

      &:focus {
        outline: none;
        border: 1px solid darken($color-gray, 5);
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
      }
    }

    button {
      width: 32px;
      height: 32px;
      float: left;
      background: none;
      border: 0px;
      padding: 0px;
      margin: 0px 0px 0px -40px;
      font-size: 21px;
      border-left: 1px solid $color-gray;

      .fa {
        float: left;
        margin: -2px 0px 0px 9px;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
  .sec_nav {
    float: right;
    margin-top: 5px;
    margin-left: 10px;

    .cart {
      position: relative;
      float: left;

      &:hover {
        opacity: 0.5;
      }

      a {
        border-right: 0px;
      }

      .fa {
        font-size: 28px;
        float: left;
        margin-left: 10px;
        margin-top: 3px;
        color: $color-text;
      }

      span {
        position: absolute;
        top: -5px;
        right: -20px;
        border-radius: 20px;
        min-width: 25px;
        height: 25px;
        font-weight: $bold;
        color: $color-white;
        text-align: center;
        line-height: 23px;
        background: $color-primary;
      }
    }

    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      float: left;

      li {
        float: left;

        a {
          color: $color-text;
          padding: 8px;
          float: left;
          border-right: 1px solid $color-gray;

          @media(max-width: 991px) {
            //padding: 1px !important;
            float: right !important;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .cart-bottom {
    display: none;
    a {
      .fa{
        font-size: 28px;
        float: right;
        margin-left: 10px;
        margin-top: 15px;
        color: white;
      }
      span {
        position: absolute;
        top: 10px;
        right: 0px;
        border-radius: 20px;
        min-width: 25px;
        height: 25px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        line-height: 23px;
        background: #000000;
      }
    }
  }

  @media(max-width: 995px){
    .cart-bottom {
      display: none;
      a {
        .fa{
          font-size: 28px;
          float: right;
          margin-left: 10px;
          margin-top: 9px;
          color: white;
        }
        span {
          position: absolute;
          top: 6px;
          right: 0px;
          border-radius: 20px;
          min-width: 25px;
          height: 25px;
          font-weight: 700;
          color: #fff;
          text-align: center;
          line-height: 23px;
          background: #000000;
        }
      }
    }

  }

  @media(max-width: 815px){
    .cart-bottom {
      a {
        .fa{
          font-size: 28px;
          float: right;
          margin-left: 10px;
          margin-right: 30px;
          margin-top: 9px;
          color: white;
        }
        span {
          position: absolute;
          top: 6px;
          right: 30px;
          border-radius: 20px;
          min-width: 25px;
          height: 25px;
          font-weight: 700;
          color: #fff;
          text-align: center;
          line-height: 23px;
          background: #000000;
        }
      }
    }
  }

  @media(max-width: 747px){
    .cart-bottom{
      display: none;
    }
  }


  .prim_nav {
    background: $color-primary;
    width: 100%;
    float: left;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

    @media(max-width: 991px) {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }

    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;

      li {
        float: left;

        &.active a {
          background: lighten($color-primary, 3);
        }

        &.first {
          border-left: 1px solid rgba(255, 255, 255, 0.1);
        }

        a {
          color: $color-white;
          padding: 15px 15px;

          @media(max-width: 992px) {
            padding: 10px 8px;
          }

          float: left;
          font-weight: $bold;
          border-right: 1px solid rgba(255, 255, 255, 0.1);

          &:hover {
            background: lighten($color-primary, 5);
          }

        }

        &.sale {
          a {
            background: darken($color-primary, 10);
            text-transform: uppercase;
          }

          &:hover {
            a {
              background: lighten($color-primary, 5);
            }
          }
        }
      }
    }
  }
}

@media(max-width: 992px) {
  .topbar .search {
    display: none;
  }
}

@media(max-width: 736px) {
  .header-spacing {
    padding-bottom: 115px !important;
  }
}

@media(max-width: 991px) {
  .header-spacing {
    padding-bottom: 115px;
  }
}

@media(max-width: 780px) {
  .sec_nav {
    margin-right: 15px;
  }

  .search {
    margin-right: 3px;
  }
}

.menu-item {
  @media(max-width: 744px) {
    //width: 100%;
    //float: none;

    //a{
    //	border: none !important;
    //}
  }
}

.menu-border {
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 9px;
}

@media(max-width: 991px) {
  #menu-headmenu {
    display: none;
  }

  .topbar .prim_nav ul li.first {
    border: none;
  }

}

@media(max-width: 991px) {
  .topbar .prim_nav ul li {
    float: left;
    border: 0px;
    width: 100% !important;

    a {
      width: 100%;
      float: left;
      border: 0px;
    }
  }

  .topbar .search input {
    //width: 100%;
    //margin-bottom: 10px;
    //margin-top: 4px;
  }

  .search {
    //float: none !important;
  }
}

.show-menu-items {
  @media(min-width: 992px) {
    display: none;
  }

  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  line-height: 15px;
  padding: -4px 5px 0px 5px;

  &-icon {
    font-size: 16px;
    margin-left: 3px;
  }
}

@media(min-width: 991px) {
  #menu-headmenu {
    display: block !important;
  }
}

@media (max-width: 991px) {

  .topbar .search button {
    width: 32px;
    height: 32px;
    float: left;
    background: none;
    border: 0;
    padding: 0;
    margin: 0px -32px 0 0;
    font-size: 21px;
    border-right: 1px solid #dedede;
  }

  .topbar .search input {
    width: 283px;
    float: right;
    border: 1px solid #dedede;
    border-radius: 4px;
    height: 32px;
    line-height: 40px;
    padding: 0 21px 0px 35px;
    box-sizing: border-box;
  }

  .topbar .search button .fa {
    float: left;
    margin: -1px 0 0 6px;
  }
}

@media (max-width: 566px) {
  .header-spacing {
    padding-bottom: 110px !important;
  }
}

@media (max-width: 625px) {
  // Klantenservice en Mijn account weg, alleen nog icon
  .menuSpan {
    display: none;
  }

  .sec_nav {
    i {
      font-size: 28px;
      padding: 0 2px;
    }

    .fa-shopping-cart {
      font-size: 36px !important;
      float: left;
      margin-left: 0px !important;
      margin-top: 3px;
      color: #1d1d1d;
    }
  }

  .topbar .logo {
    img {
      height: 30px;
    }
  }
}

@media (max-width: 500px) {

  .sec_nav {
    i {
      font-size: 20px;
      padding: 0 2px;
    }

    .fa-shopping-cart {
      font-size: 29px !important;
      float: left;
      margin-left: 0px !important;
      margin-top: 3px;
      color: #1d1d1d;
    }

    ul {
      display: none;
    }
  }

  .topbar h2.logo {
    font-size: 23px;
    margin: 5px 0 20px;
    float: left;
  }

  .topbar .search input {
    width: 100%;
    float: right;
    border: 1px solid #dedede;
    border-radius: 4px;
    height: 32px;
    line-height: 33px;
    padding: 0 21px 0 35px;
    box-sizing: border-box;
  }

  .topbar .search {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .sec_nav {
    i {
      font-size: 18px;
      padding: 0 2px;
    }

    .fa-shopping-cart {
      font-size: 24px !important;
      float: left;
      margin-left: 0px !important;
      margin-top: 4px;
      color: #1d1d1d;
    }
  }

  .topbar .sec_nav .cart span {
    position: absolute;
    top: -4px;
    right: -9px;
    border-radius: 20px;
    min-width: 20px;
    height: 20px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 21px;
    background: #3ea417;
  }

  .topbar h2.logo {
    font-size: 20px;
    margin: 5px 0 20px;
    float: left;
  }

  .topbar .search input {
    width: 100%;
    float: right;
    border: 1px solid #dedede;
    border-radius: 4px;
    height: 32px;
    line-height: 33px;
    padding: 0 21px 0 35px;
    box-sizing: border-box;
  }

  .topbar .search {
    width: 100%;
  }
}

@media (max-width: 360px) {
  .topbar h2.logo {
    font-size: 16px;
    margin: 5px 0 20px;
    float: left;
  }
}

.displayGrid {
  display: grid !important;
}

.menu-item-a {
  border-right: none;
}

.displayBlock {
  display: block !important;
}

.displayNone {
  display: none;
}

.mintop {
  top: -47px;
}


@media(max-width: 1200px){
  .topbar .prim_nav ul li a {
    color: #fff;
    padding: 15px 9px !important;
    float: left;
    font-weight: 700;
    border-right: 1px solid hsla(0,0%,100%,.1);
  }
}

@media (max-width: 991px){
  .topbar .logo img {
    height: 30px;
    position: relative !important;
    z-index: 9999 !important;
  }

  .topbar .sec_nav{
    position: relative;
    z-index: 999;
  }
}

@media(max-width: 499px){
  .show-menu-items{
    text-align: right !important;
  }
}

@media (max-width: 566px){
  .header-spacing {
    padding-bottom: 115px !important;
}
}
 
@media (max-width: 497px){
  .header-spacing {
    padding-bottom: 63px !important;
  }
}




/*--------------------------------------------------------------
# Responsive header style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}