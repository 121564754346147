/*--------------------------------------------------------------
# Footer style
--------------------------------------------------------------*/
.footer {
	margin-top: 0px;
	padding: 20px 0px 50px 0px;
	background: $color-primary;
	width: 100%;
	float: left;
	color: $color-white;

	.display{
		border-bottom: 1px solid hsla(0,0%,100%,.1);
		padding: 5px 0 15px;
		min-height: 66px;
		ul{
			padding: 3px 0;
		}
		ul li {
			font-size: 16px;
			font-weight: 600;
			display: inline-block;
			margin: 10px 19px;
			width: auto;
			float: right;
		}
		.logo{
			max-height: 40px;
			float: left;
		}
	}

	.footer-row{
		margin-top: 15px;
		.social{
			a{
				color: white!important;
			}

			a:hover, a:active, a:visited{
				color: white !important;
			}
			i{
				width: 45px;
				height: 45px;
				line-height: 40px;
				font-size: 26px;
				margin-right: 9px;
				display: block;
				float: left;
				border-radius: 50%;
				padding: 3px 0px 0px 0px;
				text-align: center;
				border: 1px solid hsla(0,0%,100%,.2);
				background-repeat: no-repeat;
				background-position: 50%;
				background-origin: content-box;
			}
		}
	}

	h3 {
		font-size: 16px;
		font-weight: bold;
		width: 100%;
		margin-bottom: 15px;
		float: left;
		margin-top: 10px;
	}

	ul {
		list-style: none;
		padding: 0px;
		margin: 0px;

		li {
			margin-bottom: 8px;
			width: 100%;
			float: left;

			&:hover {
				opacity: 0.7;
			}
		}

		a {
			color: $color-white;
		}
	}

	.tel {
		font-size: 24px;
		font-weight: bold;
		width: 100%;
		float: left;
		color: $color-white;
	}

	.mail {
		color: $color-white;
	}
}
.copy {
	padding: 10px 0px;
	float: left;
	width: 100%;
	float: left;

	.container{
		padding-top: 10px;
	}

	.rights {
		float: left;
		line-height: 25px;
	}

	.pay {
		float: right;

		img {
			max-width: 100%;
			height: 25px;
		}
	}
}

.under{
	margin-top: 20px;
	margin-bottom: 20px;
	line-height: 24px;
	color: #8a8282;
}

.brief-team{
	padding: 0px 0 25px;
	float: left;
	min-height: 200px;
	width: 50%;
.nieuwsbrief{
	margin-top: 52px;
	img{
		float: left;
		margin-right: 10px;
	}

	br{
		display: none!important;
	}

	.wpcf7 input {
		width: 150px;
		margin: 10px 10px 10px 0;
		height: 40px;
		-webkit-transition: box-shadow .6s;
		transition: box-shadow .6s;
		background: #fff;
		border: 1px solid #e5e5e5;
		padding: 0 0 0 10px;
		border-radius: 4px;
		box-shadow: inset 0 3px 5px 0 rgba(0,0,0,.05);
	}

	.wpcf7 input[type='submit']{
		background: #4bbe06;
		width: 100px;
		padding: 0;
		color: white;
		font-weight: 600;
	}

	.nieuwsbrief-titel{
		color: #178726;
		font-weight: 600;
	}
	p{
		margin: 0;
	}
}
}

@media(max-width: 999px){
	.brief-team {
		.nieuwsbrief {
			img {
				display: none;
			}
		}
	}
}

@media(max-width: 1200px ){
	.brief-team .nieuwsbrief .wpcf7 input[type=submit] {
		background: #4bbe06;
		width: 100px;
		padding: 0;
		margin-top: -10px;
		/* margin: 0 auto; */
		/* display: block; */
		color: #fff;
		font-weight: 600;
	}
}


.footer .display ul {
	padding: 3px 0;
	float: right;
	max-width: 68%;
}

.team{
	img{
		max-width: 50%;
		position: absolute;
		bottom: 0px;
		right: 0;
	}
}

@media(max-width: 992px){
	.brief-team {
		padding: 30px 0 25px;
		float: left;
		min-height: 215px;
		width: 50%;
	}
	.nieuwsbrief{
		margin-top: 0px !important;
	}
}

@media(max-width: 681px){
	.team{
		img{
			display: none;
		}
	}
	.brief-team {
		padding: 30px 0 25px;
		float: left;
		min-height: 215px;
		width: 100%;

		.nieuwsbrief{
			img{
				float: unset;
				min-width: 42%;
				max-width: 50%;
				display: block;
				margin: 0 auto 35px;
			}
		}
	}
}

.footer-news{
	float: left;
	width: 100%;
}

@media(max-width: 430px){
	.trusted{
		display: none;
	}
	.copy{
		.pay{
			float: unset !important;
			margin-bottom: -33px;
		}
	}
	.copy .pay img{
		display: block;
		margin: 0 auto;
	}
}

@media(max-width: 415px){
	.brief-team .nieuwsbrief .wpcf7 input {
		width: 100%;
		margin: 10px 10px 0px 0;
		height: 40px;
		transition: box-shadow .6s;
		background: #fff;
		border: 1px solid #e5e5e5;
		padding: 0 0 0 10px;
		border-radius: 4px;
		box-shadow: inset 0 3px 5px 0 rgba(0,0,0,.05);
	}
	.brief-team .nieuwsbrief .wpcf7 input[type=submit] {
		background: #4bbe06;
		width: 150px;
		padding: 0;
		margin-top: 8px;
		/* margin: 0 auto; */
		/* display: block; */
		color: #fff;
		font-weight: 600;
	}
	.nieuwsbrief-subtitel{
		margin-bottom: 10px;
	}
}

.container-footer{
	position: relative;
}

@media(max-width: 1200px){
	.footer-usps li:first-child{
		display: none !important;
	}
}

@media(max-width: 640px){
	.footer-usps{
		display: none !important;
	}
}

.footer-contact-p{
	position: relative;
	float: left;
}

/*--------------------------------------------------------------
# Responsive footer style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}