/*--------------------------------------------------------------
# Comments
--------------------------------------------------------------*/
.product {
	padding: 10px;
	width: 100%;
	float: left;
	box-sizing: border-box;
	position: relative;
	color: $color-text;
	text-align: center;

	&.single-product {
		&:hover {
			background: none;
		}
	}

	a {
		color: $color-text;
		text-decoration: none;
	}

	&:hover {
		background: $color-gray-light;
		color: $color-text;
		.name {
			color: $color-primary;
		}
	}

	.num {
		position: absolute;
		right: 5px;
		top: 5px;
		background: #fff;
		width: 40px;
		height: 40px;
		z-index: 100;
		border-radius: 40px;
		background:$color-primary;
		line-height: 38px;
		text-align: center;
		font-size: 24px;
		font-weight: bold;
		color: #fff;
	}

	.icon.sale, .icon.new {
		position: absolute;
		left: 0px;
		top: 0px;
		padding: 5px;
		background: $color-primary;
		color: #fff;
		float: left;
		font-weight: bold;
		border-radius: 0px 0px 5px 0px;
	}

	figure {
		width: 100%;
		height: 170px;
		float: left;
		padding: 0px;
		margin: 0px 0px 5px 0px;
		background: #fff;
		position: relative;
		border: 1px solid $color-gray;
	}

	.name {
		font-size: 14px;
		font-weight: bold;
	}

	.stars {
		width: 100%;
		float: left;
		color: $color-stars;
	}
	.price {
		width: 100%;
		float: left;
		margin: 5px 0px;
		float: left;
		width: 100%;

		.woocommerce-Price-amount {
			color: #73af35;
			padding-top: 7px;
			font-size: 30px !important;
			font-weight: bold;
			margin-top: 3px;
			float: left;
			width: 100%;
			text-decoration: none;
			line-height: 23px;
		}

		del {
			.amount {
				opacity: 0.5;
				font-size: 17px !important;
				text-decoration: line-through;
			}
			.woocommerce-Price-currencySymbol{
				font-size: 17px !important;
			}
		}
	}

	.stock {
		width: 100%;
		float: left;
		font-size: 12px;
		margin-top: 10px;

		.round {
			width: 10px;
			height: 10px;
			border-radius: 15px;
			display: inline-block;

			&.green {
				background: $color-green;
			}

			&.red {
				background: $color-red;
			}
		}
	}
}