/*--------------------------------------------------------------
# Buttons
--------------------------------------------------------------*/
.bread {
	width: 100%;
	float: left;
	padding: 10px;

	a {
		color: $color-primary;
		font-weight: bold;
	}
}

#breadcrumbs{
	margin-bottom: 3px !important;
}

@media(max-width: 767px){
	.bread{
		padding: 10px 0px 0px 0px !important;
	}
}