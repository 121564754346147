// Font weights
$regular: 400;
$bold: 700;

// Base Font
$base-font-family: 'Biryani', sans-serif;
$base-font-weight: $regular;
$base-font-size: 16px;
$base-line-height: 26px;

//base colors
$color-primary: #3ea613;
$color-secondary: #3f961b;
$color-green: #73af35;
$color-stars: #efb20d;
$color-red: #c11e35;
$color-white: #ffffff;
$color-gray: darken(#F7F7F7, 10);
$color-gray-light: #F7F7F7;

//link colors
$color-link: $color-primary;
$color-link-dark: darken($color-primary, 10);
$color-link-light: $color-white;

//text colors
$color-text: #1d1d1d;
$color-text-light: lighten($color-text, 10);