/*--------------------------------------------------------------
# Sidebar style
--------------------------------------------------------------*/
.sidebar {
    width: 240px;
    float: left;
    
    .filter {
        width: 100%;
        float: left;
        padding: 15px;
        box-sizing: border-box;
        border:1px solid $color-gray;
        border-bottom: 5px solid $color-primary;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
    }

    h3 {
    	font-weight: $bold;
    	font-size: 16px;
    	margin:0px;
        width: 100%;
        float: left;
        float:left;
        border-bottom: 1px solid $color-gray;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    ul {
    	list-style-type: none;
    	padding: 0;
        margin:0px 0px 10px 0px;
        width: 100%;
        float: left;

        li {
            font-size: 14px;
            line-height: 16px;
            padding: 0 0 3px;
            width: 100%;
            float: left;

            &.active a, &.cur_cat a {
                color:$color-primary;
                font-weight: bold;
            }

            a {
                width: 100%;
                float:left;
                padding:4px 0px;
            	color: $color-text;

                span {
                    font-size:10px;
                }

            	&:hover, &:focus {
            		color: $color-primary;
            	}
            }
        }
    }

    .usp_box ul .fa {
        margin-top: 0px;
    }
}


/*--------------------------------------------------------------
# Responsive Sidebar style
--------------------------------------------------------------*/

@include breakpoint(lg) {

}

@include breakpoint(md) {
    .sidebar {
        display: none;
        margin-bottom: 20px;
        
        h3 {
            margin-top: 10px;
        }

        ul {
            margin-bottom: 0px;
        }
    }

    .mobile_filters {
        display: block;
    }
}

@include breakpoint(sm) {}
