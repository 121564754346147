/*--------------------------------------------------------------
# Hero style
--------------------------------------------------------------*/
.hero {
	//@media(min-width: 744px){
	//	margin-top: 22px;
	//}
	width: 100%;
	float: left;

	.pay {
		float: right;
		margin: 10px 0px;

		img {
			width: 200px;
		}
	}

	.item {
		width: 100%;
		float: left;
		position: relative;
		transition:all 0.3s ease;
		border-radius: 2px;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

		&:hover {
			opacity: 0.9;

			h3 {
				background: rgba(0, 0, 0, 1);
			}
		}

		h3 {
			position: absolute;
			right: 0px;
			bottom: 0px;
			font-size: 16px;
			background: rgba(0, 0, 0, 0.7);

			.fa {
				margin-left: 10px;
			}
		}

		&.big {
			height: 400px;
			text-align: center;

			h3 {
				position: absolute;
				right: 0;
				bottom: 0;
				font-size: 16px;
				background: rgba(0,0,0,.7);
				float: none;
				max-width: 250px;
				padding: 10px;
			}
		}

		&.small {
			height: 185px;
			margin-top: 30px;
		}

		&.top-item  {
			margin-top: 0px;
		}
	}

	.col-md-7{
		@media(max-width: 990px){
			.top{
				width: 53%;
				float:right;
			}

			.bottom{
				width: 53%;
			}
		}

		@media(max-width: 660px){
			.top{
				width: 53%;
				float:right;
			}

			.bottom{
				width: 55%;
			}
		}

		@media(max-width: 530px){
			.top{
				width: 55%;
				float:right;
			}

			.bottom{
				width: 55%;
			}
		}


		@media(max-width: 455px){
			.top{
				width: auto;
				float: none;
			}

			.bottom{
				width: auto;
			}
		}
	}
}

@media(max-width: 990px){
	.hero .item.big {
		height: 260px;
		text-align: center;
	}

	.hero .item.small {
		margin-top: 15px;
	}
}

/*--------------------------------------------------------------
# Responsive Usps style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}