/*--------------------------------------------------------------
#  style
--------------------------------------------------------------*/
.woocommerce-account .woocommerce-MyAccount-navigation {
  width: 20%;
}

.woocommerce-account .woocommerce-MyAccount-content {
  width: 75%;
}
@media(max-width: 992px){
  .woocommerce-account .woocommerce-MyAccount-navigation {
    width: 100%;
    float: right;
  }

  .woocommerce-account .woocommerce-MyAccount-content {
    width: 100%;
    float: left;
  }
}

.woocommerce-account {
  
  .certs {
    width: 100%;
    float: left;
    list-style: none;
    padding: 0px;

    .top-bar {
      background: #eee;
      font-weight: bold;
      float: left;
      width: 100%;
    }

    .num {
      width: 10%;
      float: left;
      padding: 10px;
      font-weight: bold;
    }

    .name {
      width: 30%;
      padding: 10px;
      float: left;
    }

    .date {
      width: 20%;
      float: left;
      padding: 10px;
    }

    .status {
      width: 20%;
      float: left;
      padding: 10px;

      &.Goed {
        .icon {
          background: #4bbe06;
        }
      }

      &.Afgekeurd {
        .icon {
          background: #be0f06;
        }
      }

      .icon {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        float: left;
        margin-right: 5px;
        background: #eee;
        margin-top: 2px;
      }
    }

    .action {
      width: 20%;
      float: left;
      padding: 10px;
    }

    .cert_row {
      border-bottom: 1px solid #eee;
      padding: 10px 0px;
      line-height: 18px;
      width: 100%;
      float: left;

      a {
        font-weight: bold;
        background: $color-primary;
        color: #fff;
        padding: 5px 10px;
        text-decoration: none;
        width: 100%;
        text-align: center;
        float: left;
      }
    }

    @media(max-width: 760px){
      .top-bar {
        display: none;
      }
      .cert_row {
        margin-bottom: 10px;
        .num, .action, .name {
          width: 100%;
        }  
        .date, .status {
          width: 50%;
        } 
      }
    }
  }

  .login {
    max-width: 350px;
  }
  
  .woocommerce .woocommerce-MyAccount-navigation {
    margin-bottom: 25px;

    ul {
      padding: 0px;
      list-style: none;

      li {
        width: 100%;
        float: left;
        border-top:1px solid $color-gray;
        font-weight: $bold;

        &.is-active a, a:hover {
          text-decoration: none;
          background: lighten($color-gray, 7);
        }

        a {
          padding: 10px 10px;
          width: 100%;
          box-sizing: border-box;
          text-decoration: none;
          float: left;
          color: $color-text;
        }
      }
    }
  }

  .woocommerce .woocommerce-MyAccount-orders {

    thead {
      background: $color-primary;
      color: #fff;
      width: 100%;
      float: left;
    }

    tbody {
      width: 100%;
      float: left;
    }

    tr {
      width: 100%;
      float: left;
    }

    .order-number {
      width: 10%;
      float: left;
    }

    .order-date {
      width: 20%;
      float: left;
    }

    .order-status {
      width: 20%;
      float: left;
    }

    .order-total {
      width: 30%;
      float: left;
    }

    .order-actions {
      float: right;
      padding-right: 0px;
    }

    .order {
      border-bottom: 1px solid $color-gray;
    }
  }

  .woocommerce .woocommerce-Address {
    border-top: 2px solid $color-secondary;
    padding-top: 10px;

    .woocommerce-Address-title {
      width: 100%;
      float: left;

      h3 {
        border-bottom: 0px;
        margin-top: 0px;
      }
    }

    address {
      width: 100%;
      float: left;
      line-height: 26px;
    }
  }

  .woocommerce .woocommerce-MyAccount-content {
    
    a.button, input.button {
      background:$color-secondary;
      color: #fff;

      &:hover {
        background: darken($color-secondary, 10);
      }
    }

    h2, h3, legend {
      border-bottom: 1px solid $color-gray;
      font-size: 18px;
      font-weight: $bold;
      padding-bottom: 10px;
      margin-bottom: 0px;
      margin-top: 15px;
      color: $color-text;
    }

    legend {
      width: 100%;
      float: left;
    }

    address {
      width: 100%;
      float: left;
      line-height: 26px;
      margin-top: 10px;
    }

    .order_details {
      tfoot {
        background: lighten($color-gray, 7);

        td {
          border-top: 0px;
        }
      }

      thead {
        background: $color-primary;
        color: #fff;
      }

      tbody {
        tr, th, td {
          border-top: 1px solid $color-gray;
        }
      }
    }
    .customer_details {
      tr {
        th {
          border-top: 0px;
        }
      }
    }
  }
}

.woocommerce #customer_login {
  h2, h3 {
      border-bottom: 1px solid $color-gray;
      font-size: 18px;
      font-weight: $bold;
      padding-bottom: 10px;
      margin-bottom: 0px;
      margin-top: 15px;
      color: $color-text;
  }

  form.login {
    border: 0px;
    padding: 0px;
    margin: 10px 0px;
    box-sizing: border-box;
    width: 100%;
    float: left;
  }

  form.register {
    border: 0px;
    padding: 20px;
    margin: 10px 0px;
    box-sizing: border-box;
    width: 100%;
    float: left;
    border-radius: 0px;
    background: lighten($color-gray, 7);
  }
}

.woocommerce {
  a.button, input.button {
    background:$color-secondary;
    color: #fff;

    &:hover {
      background: darken($color-secondary, 10);
      color: #fff;
    }
  }
}

@include breakpoint(sm) {
  .woocommerce-account .woocommerce .woocommerce-MyAccount-navigation {
    width: 100%;
    float: left;
    margin-bottom: 20px;
  }
  .woocommerce-account .woocommerce .woocommerce-MyAccount-content, .woocommerce-account .woocommerce .woocommerce-Address {
    width: 100%;
    float: left;
  }
  .woocommerce-account .woocommerce .woocommerce-MyAccount-orders {
    width: 100%;
    float: left;
    margin-top: 0px;

    tr {
      width: 100%;
      float: left;
    }

    .order-number, .order-date, .order-total, .order-status, .order-actions  {
      width: 100%;
      float: left;
    }
  }
}