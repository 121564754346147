/*--------------------------------------------------------------
# Checkout
--------------------------------------------------------------*/
.woocommerce table.shop_table {
  border: none;

  th,
  td,
  tfoot th{
    border: none;
  }
}

.woocommerce form.login {
	width: 100%;
	float: left;
	margin: 0px 0px 20px 0px;
}
.wc-proceed-to-checkout {
	width: 100%;
	float: left;
	padding: 0px 0px 10px 0px !important;
}

.woocommerce-checkout .woocommerce .custom_checkout {
	width: 100%;
	float: left;
	
	.overlay_checkout {
		position: relative;
		bottom: 0px;
		width: 100%;
		height: 50px;
		margin-top: -50px;
		float: left;
		background: url(../images/overlay.png) repeat-x;
		background-size: 100% 100%;
	}

	.checkout_cart {
		width: 100%;
		float: left;
		max-height: 150px;
		position: relative;
	    overflow-y: scroll; /* has to be scroll, not auto */
	    -webkit-overflow-scrolling: touch;

		ul {
		    padding: 0px;
		    list-style: none;
		    width: 100%;
		    padding-bottom: 10px;
		    float: left;

		    li {
		        width: 100%;
		        float: left;
		        border-bottom: 1px solid darken($color-gray-light, 5);
		        padding:5px;
		        background: #fff;

		        figure {
		            width: 40px;
		            height: 40px;
		            float: left;
		        }
		        .text {
		            width: calc(100% - 55px);
		            float: right;

		            p {
		            	width: 100%;
		            	float: left;
		            	margin: 0px;
		            }

		            a {
		                font-weight: $bold;
		                line-height: 13px;
		                font-size: 13px;
		                width: 100%;
		                float: left;
		                color: $color-primary;
		            }

		            .meta, .price {
		                float: left;
		                font-size: 13px;
		                width: 50%;
		                color: darken($color-gray, 25);
		            }

		            .price {
		                text-align: right;
		                font-weight: bold;
		            }
		        }
		    }
		}
	}

	h3 {
		font-size: 18px;
	}

	#customer_details {
	    background: #fff;
	    box-shadow: 0 2px 4px rgba(0,0,0,.1);
	    border:1px solid $color-gray;
		padding: 15px;
		box-sizing: border-box;
		width: 100%;
		float: left;
	
		.form-row.woocommerce-mailchimp-opt-in {
			width: 100%;
			margin-top: 10px;

			label {
				line-height: 21px;
			}
		}

		h3 {
			margin: 0px;
			padding-bottom: 15px;
			margin-bottom: 10px;
			border-bottom: 1px solid $color-gray;
		}

		.select2-container--default .select2-selection--single {
			height: 44px;
			line-height: 44px;
		}

		.select2-container--default .select2-selection--single .select2-selection__rendered {
			line-height: 44px;
		}

		.select2-container--default .select2-selection--single .select2-selection__arrow {
			height: 44px;
		}

		.form-row-first, .form-row-last, .form-row-first, .form-row-last, .form-row {
			width: 50%;
			padding: 5px;
			margin: 0px;
			float: left;
			clear: none;
			min-height: 72px;

			label {
				font-weight: $regular;
				margin-bottom: 0px;
			}

			input {
				padding: 13px 10px;
			}
		}

		.field-small {
			width: 25%;
			float: left;
		}

		.notes {
			width: 100%;
			float: left;

			textarea {
				height: 100px;
			}
		}

		#billing_country_field, #shipping_country_field {
			label, .country_select {
				width: calc(50% - 6px) !important;
				font-weight: $regular;
				margin-bottom: 0px;
			}

			.select2-container .select2-choice {
				padding: 5px 8px;
				border-radius: 0px;
			}
		}
		
		.shipping_title {
			margin-top: 20px;
			width: 100%;
			float: left;
		}

		#ship-to-different-address {
			border-bottom:1px solid $color-gray;
			border-top:1px solid $color-gray;
			float: left;
			width: 100%;
			padding: 10px 0px;
			margin-top: 15px;

			label {
				font-size: 16px;
				width: calc(100% - 20px);
				float: right;
			}

			#ship-to-different-address-checkbox {
				float: left;
				margin-top: 11px;
			}
		}
	}
	
	.order_review {
		width: 100%;
		float: left;

		.box {
			width: 100%;
		    background: #fff;
		    box-shadow: 0 2px 4px rgba(0,0,0,.1);
		    border:1px solid $color-gray;
			padding: 15px;
			box-sizing: border-box;
			float: left;
			
			.fee {
				width: 100%;
				float: left;
				margin-bottom: 10px;
				font-weight: bold;
			}
			
			h3 {
				margin: 0px;
				padding-bottom: 15px;
				margin-bottom: 0px;
				border-bottom: 1px solid $color-gray;
			}

			.top {
				width: 100%;
				
				.product-name {
					font-weight: $bold;
				}

				.product-total {
					font-weight: $bold;
				}
			}

			.cart_item {
				width: 100%;
				float: left;
				padding: 5px 0px;
				border-top: 1px solid $color-gray;

				p {
					margin-bottom: 2px;
				}
			}

			.product-name {
				width: 70%;
				float: left;

				.variation {
					margin-bottom: 0px;
				}
			}

			.product-total {
				width: 30%;
				float: left;
			}

			.cart-subtotal {
				width: 100%;
				float: left;

				.title {
					float: left;
				}

				.price {
					float: right;
					font-weight: $bold;
				}
			}

			.order-total {
				border-top: 1px solid $color-gray;
				width: 100%;
				padding-top: 10px;
				float: left;
				line-height: 21px;

				.includes_tax {
					width: 100%;
					float: left;
				}
			}

			.shipping {
			  width: 100%;
			  float: left;

			  h3 {
			  	display: none;
			  }

			  ul#shipping_method {
			    width: 100%;
			    float: left;
			    padding: 0px;

			    li {
			      width: 100%;
			      float: left;
			      padding: 0px;
			      text-indent: 0px;
			    }
			  }

			  .top {
			    width: 100%;
			    float: left;
			  }

			  label {
			    width: 100%;
			    padding: 3px 0px;
			    float: left;
			    border-top: 1px solid $color-gray;
			  }

			  .extra_info {
			    width: 100%;
			    float: left;
			    color: $color-text;
			    font-weight: $regular;
			    font-size: 12px;
			    line-height: 16px;
			  }
			}
		}

		#payment {
			width: 100%;
			float: left;
			background: none;
			border: 0px;
			margin-top: 20px;

			.about_paypal {
				display: none !important;
			}

			img {
				width: 40px;
			}

			#place_order {
				width: 100%;
				float: left;
				padding: 15px;	
			}

			.payment_method_afterpay_openinvoice {
				img {
					width: 60px;
				}

				.form-row {
					width: 100%;
					float: left;
					margin-bottom: 0px;

					label {
						margin-bottom: 0px;
					}
				}
			}

			.place-order {
				width: 100%;
				float: left;
				padding: 0px;
			}

			.wc_payment_methods {
				width: 100%;
			    background: #fff;
			    box-shadow: 0 2px 4px rgba(0,0,0,.1);
			    border:1px solid $color-gray;
				padding: 15px;
				box-sizing: border-box;
				float: left;

				label {
					max-width: 90%;
					line-height: 21px;
				}

				input[type="radio"] {
					float: left;
					margin-top: 7px;
				}
			}

			div.payment_box {
				margin:0px 0px 10px 0px;
				padding: 0px;
				background: none;

				&:before {
					display: none;
				}
			}	

			.wc_payment_method.payment_method_raboomnikassa {
				background: url(../images/rabo.png) top left no-repeat;
				padding-top: 25px;
				background-size: 135px auto;
				margin-bottom: 5px;
				border-bottom: 1px solid darken($color-gray, 10);
			}

			.terms {
				width: 100%;
				float: left;
				font-weight: 400;
				margin: 10px 0 0;
				padding: 15px;
				line-height: 21px;
				box-shadow: 0 2px 4px rgba(0,0,0,.1);
				border: 1px solid #dedddd;
				background: #fff;
				font-size: 14px;

				label {
					font-weight: $regular;
					line-height: 21px;

					a {
						color: $color-text;
						text-decoration: underline;
					}
				}
			}

			.button.alt {
			  background: $color-green;
			  font-size: 16px;
			  margin-top:20px;
			  margin-bottom: 10px;
			  float: left;
			  width: 100%;

			  &:hover {
			    background: darken($color-green, 10);
			    text-decoration: none;
			  }

			  &:active, &:focus {
			    text-decoration: none;
			  }
			}
		}
	}
}

.woocommerce-cart, .woocommerce-checkout {
	.page-header {
		margin-bottom: 0px;
	}
}

.thankyou {
	width: 100%;
	float: left;
	text-align: center;

	h1 {
		font-size:40px;
		margin-top: 50px;
		margin-bottom: 5px;
	}

	h2 {
		margin-top: 0px;
		font-size: 21px;
	}

	.step_intro {
		margin-top: 50px;
		font-size: 18px;
		width: 100%;
		float: left;
		font-weight: bold;
	}

	.steps {
		width: 520px;
		left: 50%;
		margin-left: -260px;
		position: relative;
	}

	.step {
		width: 100%;
		margin-bottom: 30px;
		float: left;

		.icon_wrap {
			float: left;
			width: 60px;
			height: 60px;
			background: $color-secondary;
			border-radius: 80px;
			margin: 0px 20px 10px 0px;
			color: #fff;
			padding-left: 2px;
			text-align: center;
			
			.fa, .icon {
				line-height: 60px;
				font-size: 33px;
			}

			.icon {
				line-height: 65px;
				font-size: 30px;
			}
		}

		.text {
			width: 420px;
			float: left;
			text-align: left;

			h3 {
				font-size: 18px;
				margin:13px 0px 0px 0px;
			}

			p {
				line-height: 21px;
			}
		}
	}
}
@include breakpoint(md) {
	.woocommerce-checkout .woocommerce .custom_checkout .order_review .box {
		margin-top: 20px;
	}
}
@include breakpoint(sm) {
	.woocommerce-checkout .woocommerce .custom_checkout #customer_details #billing_country_field, .woocommerce-checkout .woocommerce .custom_checkout #customer_details #shipping_country_field {
		select, .country_select {
			width: 100% !important;
			float: left;
		}

		label {
			width: 100% !important;
		}
	}
}

@media (max-width: 600px) {
	.woocommerce-checkout {
		.form-row {
			width: 100% !important;
			float: left;

			&.field-small {
				width: 50% !important;
			}
		}
	}

	.thankyou {

		h1 {
			font-size: 40px;
			margin: 0px;
		}

		h2 {
			font-size: 18px;
		}

		.steps {
			width: 100%;
			left: 0px;
			margin: 0px;

			.icon_wrap {
				left: 50%;
				margin-left: -30px;
			}

			.text {
				width: 100%;
				float: left;
				text-align: center;

				h3 {
					font-size: 18px;
				}
			}
		}
	}
}

.woocommerce-checkout{
	.order-total{
		.amount{
			color: #3ea613;
		}
	}
}