.support{
	h1{
		width: 100%;
		margin-bottom: 10px;
	}

	.support-left{
	    float: left;
	    width: 270px;
	    padding-right: 20px;
	}

	.wpcf7-response-output{
    margin-top: 50px;
    margin-left: 0;
    padding: 10px;
	}

	.support-right{
	    float: left;
	    width: calc(100% - 270px);
	    padding-left: 20px;
	    border-left: 1px solid #e5e5e5;
	}
	.pluimvee-form{
    	padding: 7px 2px 7px 8px;
	    box-shadow: inset 0 3px 5px 0 rgba(0,0,0,.05);
	    border-radius: 3px;
	    border: none;
	    border: 1px solid #e5e5e5;
	    margin-bottom: 12px;
	}

	.wpcf7-list-item{
		margin: 0 !important;
	}

	.pluimvee-form::placeholder{
    	color: #969696;	
	}

	.wpcf7{
		margin-top: 25px;
		strong{
		font-size: 20px !important;
		line-height: 15px;
		}
	}

	select{
	    height: 35px!important;
	}

	.wpcf7-validation-error{
	display: block;
    padding: 20px 0px !important;
    text-align: center !important;
	}

	.wpcf7-submit{ 
	-webkit-appearance: button;
    cursor: pointer; 
    background: #3ea613;
    padding: 11px 27px;
    border: 1px solid #3ea613;
    float: left;
    margin-bottom: 10px;
    font-size: 18px;
    text-align: center;
    color: #fff;
	} 

	.pluimvee-gegevens{
		width: 200px; 
		margin-right:  7px; 
	}

	textarea{
		min-width: 390px !important;
	}

	.support-row{
		float: left;
		margin-top: 10px;
		margin-left: 0px;
		margin-right: 0px;
		width: 100%;
	}

	.support-col-inner{
	    position: absolute;
	    width: 100%;
	    padding: 0 5px;
	    float: left;
	    left: auto;
	    top: 50%;
	    transform: translateY(-50%);
	}

	.support-col{
	    text-align: center;
	    min-height: 230px;
	    display: block;
	    padding: 0;
	    position: relative;
	    border: 1px solid #f5f5f5;
	    margin-bottom: 0px;

		.fa{
			font-size: 65px;
			color: #3ea613;
		}
	}
}  

@media(max-width: 991px){
	.support .support-col{
		width: 50%;
		float: left;
	}
}

@media(max-width: 550px){
	.support .support-col{
		width: 100%;
		float: left;
		margin-bottom: 15px;
	}
}

@media(max-width: 951px){
	.support .support-row {
	   margin: 0 !important;
	}
}



@media(max-width: 1000px){
	.support{
		.support-left{
			display: none;
		}
		.support-right{
			width: 100%;
			padding-left: 0px;
			border: none;
		}
	}
} 

@media(max-width: 500px){
	.support{
		textarea{
			min-width:unset !important;
			width: 100%;
		}
		.pluimvee-order-nr{
			max-width: 80%;
		}
	}
}