/*--------------------------------------------------------------
# Usps style
--------------------------------------------------------------*/
.featured {
	width: 100%;
	float: left;
	background: white;;
	margin: 0px;
	padding: 0px 0px 60px 0;

	.filter {
		width: 100%;
		float: left;
		background: #fff;
		margin-top: 10px;
		border:1px solid $color-gray;
		border-radius: 5px;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

		.tabs {
			width: 100%;
			float: left;
			border-bottom: 1px solid $color-gray;
			list-style: none;
			padding: 0px 10px;
			margin: 0px;

			li {
				float: left;
				font-weight: bold;
				margin: 10px;
				padding: 10px;
				float: left;
				color: $color-text;
				cursor: pointer;

				&:focus {
					outline: none;
				}

				&.slick-current {
					background: $color-primary;
					color: $color-white;
					border-radius: 5px;
				}

				&:hover {
					opacity: 0.8;
				}
				
			}
		}

		.filter_pages {
			width: 100%;
			float: left;

			:focus {
				outline: none;
			}

			.filter_page {
				width: 100%;
				float: left;

				.product {
					width: 20%;
					float: left;
					padding: 15px 10px;
					box-sizing: border-box;
					margin: 0px;
					border-left: 1px solid $color-gray;
					border-bottom: 1px solid #cecece;

					&.first {
						border-left: 0px;
					}
				}
			}
		}
	}
}

@media(max-width: 999px){
	.product{
		.name{
			font-size: 12px;
		}
		.num{
			height: 30px;
			width: 30px;
			line-height: 30px;
			font-size: 18px;
		}

		.stars{
			margin: 5px 0;
		}
	}
}

@media(max-width: 777px){
	.product{
		border-bottom: none;
	}
}

.tab_active{
	color: #3ea417 !important;
	font-weight: 600;
}

.featured-title{
	font-weight: 700;
	margin-bottom: 5px;
}


/*--------------------------------------------------------------
# Responsive Usps style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}