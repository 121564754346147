.woocommerce-error, .woocommerce-info, .woocommerce-message {
	padding: 10px;
	height: auto;
	width:100%;
	margin-bottom: 10px;
	float: left;

	&:before {
		position: relative;
		top: 0px;
		float: left;
		left: 0px;
		margin-right: 10px;
	}
}

.woocommerce form.checkout_coupon {
	width: 100%;
	float: left;
	padding: 10px;
	margin: 0px 0px 10px 0px;
}
