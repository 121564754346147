    /*--------------------------------------------------------------
# style
--------------------------------------------------------------*/

    .single-product {
        position: relative;
        text-align: left;
        
        .container {
            position: relative;
        }


        .product-media {
            position: relative;
            min-height: 1px;
            padding-left: 15px;
            padding-right: 15px;
            float: left;
            width: 52%;
            position: relative;

            .icon {
                left: 15px;
            }
            
            .media-large-slider {
                margin-bottom: 10px;
                padding: 40px;
                box-sizing: border-box;
                border: 1px solid $color-gray;
                position: relative;

                .media-large {
                    height: 425px;
                }
            }

            .product-image-footer {
              position: relative;
              display: block;
              //height: 150px;
            }
            .media-thumbnail-slider {
                width: 60%;
                float: left;
                position: relative;

                :focus {
                    outline: none;
                }

                .media-thumbnail {
                    height: 95px;
                    margin: 10px;
                    padding: 10px;
                    opacity: 0.5;
                    border: 1px solid $color-gray;
                    cursor: pointer;

                    &.slick-active {
                        opacity: 1;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .NextArrow,
                .PrevArrow {
                    position: absolute;
                    top: 50%;
                    width: 25px;
                    height: 45px;
                    background: $color-secondary;
                    border: 0 none;
                    margin-top: -24px;
                    text-align: center;
                    font: 20px/45px FontAwesome;
                    color: #FFF;
                    z-index: 5;
                    opacity: 0.6;
                    &:hover,
                    &:focus {
                        outline: none;
                        opacity: 1;
                    }
                }
                .NextArrow {
                    right: 0px;
                }
                .PrevArrow {
                    left: 0px;
                }
                .NextArrow:before {
                    content: '\f105';
                }
                .PrevArrow:before {
                    content: '\f104';
                }
            }
            .share {
                width: 55%;
                float: left;
                height: 115px;
                position: relative;
                &.no-thumbs {
                    height: 45px;
                    width: 100%;
                }
                .share-icons {
                    position: absolute;
                    right: 0;
                    bottom: 10px;
                    span {
                        color: #b9b9b9;
                        margin: 0;
                        font-size: 15px;
                        float: left;
                        margin-right: 10px;
                    }
                    a {
                        color: $color-primary;
                        font-size: 30px;
                    }
                }
            }
        }
        .product-info {
            position: relative;
            min-height: 1px;
            padding-left: 15px;
            padding-right: 15px;
            float: left;
            width: 48%;

            .single_variation_wrap .single_variation_wrap {
                display: none !important;
            }

            .yith_wapo_groups_container {
                width: 100%;
                float: left;
                margin: 0px !important;

                .form-row-wide {
                    border-bottom: 1px solid #e5e5e5;
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    margin-top: -5px;
                }

                .ywapo_input {
                    padding:5px;

                    &:focus {
                        outline: none;
                    }
                }

                input {
                    width: 50%;
                }

                .yith_wapo_group_total {
                    display: none !important;
                }

                .ywapo_input_container_text {
                    margin-bottom: 0px;
                }

                .woocommerce-Price-currencySymbol {
                    font-size: 14px;
                }

                label {
                    margin-bottom: 0px !important;
                }

                h3 {
                    display: none;
                }

                .ywapo_label, .ywapo_label_price {
                    width: 100%;
                    float: left;
                }

                .ywapo_label_price {
                    width: 100%;
                    float: left;
                    margin-top: -5px;
                }
            }

            .block {
                width: 100%;
                float: left;
            }
            h1 {
                font-family: $base-font-family;
                font-size: 26px;
                line-height: 32px;
                font-weight: $bold;
                text-transform: none;
                margin: 0px 0px 0px 0px;
                width: 100%;
                float: left;
            }
            h2 {
                font-size: 21px;
                width: 100%;
                margin: 0px 0px 0px 0px;
                opacity: 0.6;
                float: left;
            }

            .tabs {
                width: 100%;
                float: left;

                .tab {
                    float: left;
                    padding: 10px 20px;
                    font-weight: bold;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.6;
                    }

                    &.active {
                        background:$color-gray-light;
                    }
                }
            }

            .tab_content {
                width: 100%;
                padding: 20px;
                background: $color-gray-light;
                display: none;
                float: left;

                &.active {
                    display: block;
                }

                .down {
                    width: 100%;
                    float: left;
                    border-bottom: 1px solid #fff;
                    color: $color-primary;
                    padding: 5px 0px;

                    &:hover {
                        opacity: 0.7;
                    }

                    .fa {
                        float: right;
                        margin-top: 5px;
                    }
                }
            }
            
            .specs {
                .rule {
                    padding: 5px 0px;
                    float: left;
                    width: 100%;
                    border-bottom: 1px solid #fff;

                    .name {
                        float: left;
                        width: 20%;
                        font-weight: bold;
                        color: #000;
                    }

                    .val {
                        float: left;
                        width: 80%;

                        p {
                            margin: 0px;
                        }
                    }
                }
            }
            .single_size {
                margin: 3px 0px 0px 0px;
                width: 100%;
                float: left;
            }

            .price_wrap {
                width:100%;
                float: left;
                margin-top: 0px;
                padding-bottom: 10px;
                margin-bottom: 17px;
                border-bottom: 1px solid #e5e5e5;
            }
            .price {
                font-size: 21px;
                font-weight: $bold;
                color: darken($color-primary, 10);

                .value {
                    font-size: 20px;
                }

                .from {
                    display: none;
                }

                del {
                    display: inline-block;
                    opacity: 0.5;
                }

                ins {
                    text-decoration: none !important;
                }
            }
            .colors {
                width: 100%;
                float: left;
                li {
                    cursor: pointer;
                    position: relative;
                    white-space: nowrap;
                    display: inline-block;
                    a {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0px;
                        top: 0px;
                    }
                }
                .color {
                    width: 30px;
                    height: 30px;
                    margin: 2px 3px;
                    background-size: 100%;
                    vertical-align: baseline;

                    &.selected {
                        top: -2px;
                        margin: 0;
                        width: 28px;
                        height: 28px;
                        cursor: default;
                        outline-color: black;
                        vertical-align: baseline;
                        &:hover {
                            top: -2px;
                            margin: 0;
                            width: 28px;
                            height: 28px;
                            cursor: default;
                            outline-color: black;
                            vertical-align: baseline;
                        }
                    }
                    &:hover {
                        width: 25px;
                        height: 25px;
                        margin: 0 2px;
                    }
                }
                .color1 {
                    background-color: black;
                }
                .color2 {
                    background-color: red;
                }
                .color3 {
                    background-color: blue;
                }
                .color4 {
                    background-color: orange;
                }
            }
            div.quantity {
                float: left;
                margin-right: 10px;
                span {
                    float: left;
                    margin-right: 10px;
                    line-height: 48px;
                }
                input {
                    border: 1px solid #dcdcdc;
                    background-color: #fff;
                    color: #6d797d;
                    width: 60px;
                    float: left;
                    padding: 10px 5px 10px 5px;
                    &:focus {
                        outline: none;
                    }
                }
            }
            .select_size {
                padding: 0px 5px 0px 0px;
                float: left;
                width: 50%;
                margin-bottom: 10px;
                position: relative;

                select {
                    top: 23px;
                }
            }

            .label {
                width: 100%;
                float: left;
                margin-bottom: 3px;
                font-weight: bold;
            }
            .customSelect {
                border: 1px solid #dcdcdc;
                background-color: #fff;
                color: #6d797d;
                width: 100%;
                padding: 10px 45px 10px 15px;
                background: #fff url(../images/select-box-bg.png) right center no-repeat;
                .customSelectInner {
                    width: 100% !important;
                }

                &.false {
                    border: 1px solid #b40c0c;
                }
            }
            .variations {
                width: 100%;
                float: left;
                padding-top: 10px;
                margin-top: 5px;
                border-top: 1px solid #e5e5e5;
            }
            .button-row {
                float: left;

                &.woocommerce-variation-add-to-cart-disabled {
                    .btn-primary {
                        opacity: 0.5;
                    }
                }
                .btn-primary {
                    background: $color-green;
                    padding: 11px 27px;
                    border: 1px solid darken($color-green, 4);
                    float: left;
                    width: 100%;
                    margin-bottom: 10px;
                    font-size: 18px;
                    text-align: center;
                    color: #fff;
                    &:hover {
                        background-color: lighten($color-green, 5);
                        border: 1px solid darken($color-green, 1);
                    }
                    &.disabled {
                        background: lighten($color-green, 5);
                        &:hover {
                            background: lighten($color-green, 10);
                        }
                    }
                }
                .btn-secondary {
                    font-size: 12px;
                    background-color: transparent;
                    border: 1px solid $color-secondary;
                    color: darken($color-secondary, 15);
                    width: auto;
                    line-height: 45px;
                    width: 100%;
                    text-align: center;
                    padding: 0px 10px 0px 10px;
                    float: left;
                    i {
                        font-size: 50px;
                        width: 50px;
                        height: 45px;
                    }
                    &:hover {
                        background-color: darken($color-secondary, 8);
                        color: #fff;
                    }
                }
                .stock {
                    width: 30%;
                    float: right;
                    line-height: 45px;
                    font-size: 16px;
                    .icon {
                        color: $color-secondary;
                        font-size: 25px;
                    }
                }
            }
            .usp {
                width: 100%;
                background-color: $color-primary;
                color: #fff;
                text-align: center;
                padding: 15px;
                margin: 20px 0px 20px 0;
                float: left;
                font-size: 13px;
                font-weight: $bold;
                i {
                    font-size: 32px;
                    width: 32px;
                    height: 28px;
                }
            }
            .woocommerce-Price-currencySymbol {
                font-size: 30px;
            }
            .woocommerce-variation-price {
                width: 100%;
                float: left;
                font-size: 18px;
                margin: 0px 0px 0px 0px;
                span {
                    font-size: 24px;
                }
            }

            .woocommerce-variation-availability {
                width: 100%;
                float: left;

                p {
                    margin: 0px;
                }
            }

            .variations {
                width: 100%;
                float: left;
            }

            table.shop-attributes td {
                padding-left: 10px!important;
            }
                
                ul.benefits {
                    list-style-type: none;
                    padding: 0;
                    float: left;
                    width: 100%;
                    margin: 0;
                    margin-top: 5px;

                    li {
                        width: 100%;
                        float: left;
                        margin-bottom: 5px;
                    }

                    .fa {
                        color: $color-green;
                        font-size: 18px;
                        float: left;
                        margin:2px 5px 0px 0px;
                    }
                }
            }

            .content {
                position: relative;
                width: 100%;
                float: left;
                overflow: hidden;
                padding: 0px 0px 20px 0px;
            }

            hr {
                border-top: 1px solid $color-gray;
                width: 100%;
                float: left;
                margin: 10px 0px 15px 0;
            }

            h3 {
                font-family: $base-font-family;
                font-weight: $bold;
                font-size: 14px;
                text-transform: none;
            }

            .content ul {
                padding-left: 20px;
            }
 
            .details {
                width: 100%;
                float: left;
                margin: 0px;
            }

            .meta {
                width: 100%;
                float: left;
                font-size: 13px;
                margin-top: 0px;
                color: lighten(#7e7e7e, 10);
                line-height: 21px;

                a {
                    color: lighten(#7e7e7e, 2);
                }
            }
        }

    .additional-products {
        margin-top: 50px;
        width: 100%;
        float: left;

        ul.products li.product {
            margin: -2px -0.2% 10px 0;
            width: 25.15%;   

            a {
                float: left;
                width: 100%;
                padding: 10px;

                &:hover {
                    background: $color-gray-light;
                }
            }
        }

        h3 {
            text-align: center;
            font-size: 21px;
            font-weight: $bold;
        }
        hr {
            width: 100%;
            float: left;
            margin-top: 10px;
            margin-bottom: 30px;
        }
        .block.product_thumbnail {
            .product_image {
                width: 60%;
                height: 150px;
            }
        }
    }
    /*--------------------------------------------------------------
# Responsive style
--------------------------------------------------------------*/

    @include breakpoint(lg) {
        .single-product {
            .product-info {
                h1 {
                    font-size: 24px;
                }
                ul {
                    &.benefits {
                        li {
                            height: auto;
                            width: 100%;
                            float: left;
                            img {
                                margin-left: 38px;
                                margin-bottom: 8px;
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(md) {
        .single-product {
            .product-media {
                width: 40%;

                .media-large-slider {
                    padding:2px;

                    .media-large {
                        height: 200px;
                    }
                }

                .media-thumbnail-slider {
                    width: 80%;

                    .media-thumbnail {
                        height: 80px;
                        padding: 1px;
                        margin: 0px 5px 0px 0px;
                    }
                }

                .share {
                    width: 100%;
                    margin-top: 20px;
                    height: auto;

                    .share-icons {
                        position: relative;
                        bottom: 0px;
                    }
                }
            }
            .product-info {
                width: 60%;
                ul {
                    &.benefits {
                        li {
                            height: auto;
                            width: 100%;
                            float: left;
                            img {
                                margin-left: 10px;
                                margin-bottom: 0;
                                margin-top: -2px;
                            }
                        }
                    }
                }
            }
            &.woocommerce {
                ul.products {
                    li.product {
                        width: 33.33%;
                        margin: 0px;
                        padding: 0px;

                        .block {
                            padding: 0px 20px 30px 20px;
                            float: left;
                            width: 100%;
                        }

                        &.last {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width:26.875em) {
        .single-product {
            .product-info {
                ul {
                    &.benefits {
                        li {
                            img {
                                margin-left: 38px;
                                margin-bottom: 8px;
                                margin-top: 0;
                                width: 100%;
                                width: calc(100% - 38px);
                                max-width: 216px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(sm) {
        .single-product {
            .product-media {
                width: 100%;

                .product-image-footer {
                    height: auto;
                }

                .share {
                    width: 100%;
                    height: auto;
                    margin-top: 0px;

                    &.no-thumbs {
                        margin-top: 0px;
                    }

                    .share-icons {
                        padding: 10px 0;
                        position: relative;
                    }
                }
                .media-thumbnail-slider {
                    width: 100%;
                    .media-thumbnail {
                        width: 80px;
                    }
                    .NextArrow,
                    .PrevArrow {
                        width: 25px;
                    }
                }
                .media-large-slider {
                    .media-large {
                        height: 300px;
                    }
                }
            }
            .product-info {
                width: 100%;

                h1 {
                    font-size: 21px;
                    line-height: 24px;
                }
                h2 {
                    font-size: 16px;
                    margin-bottom: 5px;
                }
                .price {
                    font-size: 24px;
                }
                .block {
                    padding: 15px;
                }
                .usp {
                    font-size: 12px;
                }
                ul {
                    &.benefits {
                        li {
                            font-size: 12px;
                        }
                    }
                }
                .select_size {
                    width: 100%;
                    float: left;
                    position: relative;
                    margin-top: 10px;
                }
                .customSelect {
                    width: 100%;
                }
                
                select {
                    width: 100% !important;
                    box-sizing: border-box;
                }

                .woocommerce-Price-currencySymbol {
                    font-size: 30px;
                }
            }

            .additional-products {
                margin-top: 20px;

                    ul.products li.product {
                        width: 50%;

                    &.last {
                        display: block;
                    }
                }
            }
        }
    }

    @media (max-width: 537px) {
        .media-large-slider {
            .media-large {
                height: 200px !important;
            }
        }
    }

    @media(max-width: 405px){
        .comment-form .single-product .product-info .button-row input[type=submit], .single-product .product-info .button-row .btn-primary, .single-product .product-info .button-row .comment-form input[type=submit]{
            margin-top: 10px !important;
        }
        .single-product .product-info .button-row{
            width: 100%!important;
        }
    }

    .woocommerce ul.products li.first, .woocommerce-page ul.products li.first{
        clear: unset !important;
    }