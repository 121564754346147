a:hover, a:active, a:visited, a:link {
	text-decoration: none;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0px;
	float: left;
}

.marginTop {
	margin-top: 134px;
}

.page-overlay {
	width: 100%;
	height: 100%;
	background: #fff;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 10000;
}

.footer-li{
	.check{
		float: left;
	}
}

.check{
	margin-right: 10px;
	max-width: 20px!important;
	margin-top: -2px
}

.hero-row{
	padding-bottom: 0px;
	padding-top: 15px;
}

.usp-side{
.check{   
 margin-right: 10px;
    padding: 6px 0;
    max-width: 20px!important;
    margin-top: 3px;
}
}

.z-index{
	position: relative;
	z-index: 9;
}

.uspbar-first{
	margin-bottom: 5px;
}

@media(max-width: 555px){
	.uspbar-first{
		margin-bottom: 0px;
	}

}

@media(max-width: 499px){
	.prim_nav{
		box-shadow: none !important;
		background: #fff !important;
		width: unset!important;
		position: absolute;
		top: 0;
		right: 0;
	}
	.show-menu-items{
		color: black !important;
		line-height: 24px !important;
	}
	.top-container{
		box-shadow: rgba(51, 51, 51, 0.2) 2px 2px 4px;
	}
	.topbar .logo img {
		height: 35px !important;
	}
	.topbar .prim_nav ul li a{
		color: black !important;
	}
	.topbar .sec_nav{
		display: block !important;
		margin-right: 110px !important;
	}
	.sec_nav ul {
		display: block !important;
	}
}

.box-shadow{
	box-shadow: rgba(51, 51, 51, 0.2) 2px 2px 4px;
}

@media(max-width: 433px){
	.menutext{
		display: none!important;
	}
	.show-menu-items-icon{
		font-size: 25px !important;
	}
	.topbar .sec_nav {
		margin-right: 60px!important;
	}
	.topbar .container {
		padding: 5px 15px 0 !important;
		position: relative;
	}
}

@media(max-width: 370px){
	.menu-klantenservice{
		display: none;
	}
	.topbar .prim_nav_container{
		padding: 5px 11px 0 11px !important;
	}
	.topbar .sec_nav {
		margin-right: 40px!important;
		margin-top: 6px!important;
	}
}
.alignRight{
	text-align: right !important;
}

@media(max-width: 499px){
	.header-spacing {
		padding-bottom: 63px !important;
	}
}

.archive-child-ul{
	padding-left: 5px !important;
	margin-bottom: 0px !important;
}

.archive-child-li{
	font-size: 13px !important;
}

@media(max-width: 991px){
	.db-logo{
		float: left !important;
		margin-top: 10px;
	}
}

.product .icon.new, .product .icon.sale{ 
	z-index: 2 !important;
}

.woocommerce .product-archive .product-archive-header{
	margin-bottom: 0px !important; 
}


.woocommerce .product-archive .product-archive-header .product-archive-header-content.full{
	padding-bottom: 0px !important;
}

.show-menu-items{
	cursor: pointer;
}

@media (max-width: 767px){
	.single-product .product-info .block {
    padding: 15px 0 !important
} 
}

.entry-content{
	.term-description{
		h2{
			width: 100%;
			font-size: 27px !important;
    		margin-bottom: 7px;
		}

		p{
			margin-bottom: 20px;
		}

		h3{
			width: 100%;
			font-size: 27px !important;
    		margin-bottom: 5px;
		}
	}
}

.p-footer{
	a{
		font-weight: 700 !important;
		color: white;
	}
}

.under-footer{
	a{
		font-weight: 700 !important;
		color: gray;
	}
}


.webwinkel{
	float: right;
	margin: 0 10px 0 30px;

	img{
		margin-top: -2px;
		height: 55px;
	}
}

body{
	.topbar{

		.sec_nav{
			margin-top: 10px;
		}

		.search{
			margin-top: 13px;
		}

	}
}



@media(max-width: 500px){
	.webwinkel{
		display: none;
	}
}

.checkoutbtn2{
	a{
		text-align: center;
		padding: 1em !important;
		margin-top: 0px !important;
	}
}

.total-under-terms{
	width: 100%;
	float: left;
	font-weight: 400;
	margin: 10px 0 0;
	padding: 15px;
	box-shadow: 0 2px 4px rgba(0,0,0,.1);
	border: 1px solid #dedddd;
	background: #fff;
	font-size: 18px;
	line-height: 22px;

	.includes_tax{
		width: 100%;
		float: left;
	}


}

.product-name-2{
	float: left;
	width: 100%;
	min-height: 65px;
}

.feat-prod{
	min-height: 402px;
}


@media(max-width: 511px){
	.feat-prod{
		min-height: unset;
	}
}



@media(max-width: 767px){
	.product-name-2{
		min-height: 35px;
	}
}