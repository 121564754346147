/*--------------------------------------------------------------
# Cart
--------------------------------------------------------------*/

.empty-cart {
  width: 100%;
  float: left;
  text-align: center;
  padding-bottom: 100px;

  .icon.icon-cart {
    font-size: 160px;
  }
}

.woocommerce .custom_cart {
  width: 100%;
  float: left;
  
  .pay {
    width: 100%;
    text-align: center;
    float: left;
    margin-top: 10px;

    img {
      height: 25px;
    }
  }

  .cart_totals {
    width: 100%;
  }
  
  .shop_table {
    width: 100%;
    float: left;
  }
  a.checkout-button.button.alt {
    background: $color-green;
    font-size: 16px;
    margin-top:20px;
    margin-bottom: 10px;
    float: left;
    width: 100%;

    &:hover {
      background: lighten($color-green, 10);
      text-decoration: none;
    }

    &:active, &:focus {
      text-decoration: none;
    }
  }

  .cart-collaterals {
    width: 100%;
    float: left;
    padding: 15px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
    border:1px solid $color-gray;

    h3 {
      font-size: 21px;
      font-weight: 500;
      padding-bottom: 10px;
      margin: 0px 0px 10px 0px;
      border-bottom: 1px solid $color-gray;
    }
  }

  .shipping {
    width: 100%;
    float: left;

    ul#shipping_method {
      width: 100%;
      float: left;
      padding: 0px;

      li {
        width: 100%;
        float: left;
        padding: 0px;
        text-indent: 0px;
      }
    }

    .top {
      width: 100%;
      float: left;
    }

    label {
      width: 100%;
      padding: 10px 0px;
      float: left;
      border-bottom: 1px solid $color-gray;
    }

    .extra_info {
      width: 100%;
      float: left;
      color: $color-text;
      font-weight: $regular;
      font-size: 12px;
      line-height: 16px;
    }
  }

  ul.benefits {
    list-style-type: none;
    padding: 0;
    float: left;
    width: 100%;
    margin: 0;
    padding: 15px;
    background: $color-gray-light;
    border:1px solid $color-gray;
     
      li {
          height: 32px;
          img {
              margin-left: 10px;
              margin-top: -4px;
              width: 145px;
          }
      }
  }
  
  .all_cart_items {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
    border:1px solid $color-gray;
    padding:0px 15px 15px 15px;
    box-sizing: border-box;
    width: 100%;
    float: left;
  }

  .cart_totals {
    width: 300px;
    float: right;
    text-align: right;
    margin-top: 40px;

    tr {
      float: left;
      width: 100%;

      &:before {
        content: " " !important;
        opacity: 0;
        display: none !important;
      }
      
      &.order-total {
        font-size: 18px;
        border-top:1px solid lighten($color-text, 15);
      }
      th {
        width: 45%;
        float: left;
        padding: 0px;
        display: block !important;

        &:before {
          content: " " !important;
          opacity: 0;
          display: none !important;
        }
      }
      td {
        float: left;
        width: 55%;
        padding: 0px;
        background: none !important;
        
        &:before {
          content: " " !important;
          opacity: 0;
          display: none !important;
        }

        .includes_tax {
          font-size: 14px;
          width: 100%;
          float: left;
        }
      }

      &.cart-subtotal, &.cart-shipcosts {
        th, td {
          padding-top: 0px;
          padding-bottom: 0px;
        }
      }

      &.cart-shipcosts {
        margin-bottom: 5px;
      }

      &.fee {
        margin-bottom: 5px;
      }
    }
  }

  .shop_table {
    .cart-head {
      padding: 20px 0px;
      font-size: 16px;
      font-weight: $bold;
      float: left;
      width: 100%;
      border-bottom: 1px solid $color-gray;
      color: $color-text;

      .product-name {
        width: 70%;
        float: left;
      }

      .product-quantity {
        width: 15%;
        float: left;
      }

      .product-subtotal {
        width: 15%;
        float: left;
      }
    }
    .cart-items {
      width: 100%;
      float: left;

      .cart_item {
        width: 100%;
        float: left;
        border-bottom: 1px solid $color-gray;
        padding: 20px 0px;
        position: relative;

        &.free-item {
          background: #fff;

          .product-name {
            a {
              opacity: 0.8;
              font-weight: $regular;
              font-size: 14px;
            }
          }
        }

        .product-remove {
          position: absolute;
          right: 10px;
          top: 10px;

          a.remove {
            color: lighten($color-text, 20)  !important;

            &:hover {
              background: none  !important;
              color: $color-text  !important;
              text-decoration: none  !important;
            } 
          }
        }

        .product-thumbnail {
          width: 50px;
          height: 50px;
          float: left;
          background: #fff;
          border:1px solid $color-gray;
          box-sizing: border-box;
          margin-left: 10px;  

          a {
            height: 90px;
            width: 100%;
            float: left;
          }
        }

        .product-name {
          width: 405px;
          float: left;
          margin-left: 15px;

          .stock {
            width: 100%;
            float: left;
            font-size: 12px;
            margin-top: -5px;

            .round {
              width: 10px;
              height: 10px;
              border-radius: 15px;
              display: inline-block;

              &.green {
                background: $color-green;
              }

              &.red {
                background: $color-red;
              }
            }
          }

          a, h3 {
            font-size: 16px;
            font-weight: $bold;
            margin-top: 10px;
            color: $color-primary;
            text-decoration: none;
            margin-bottom: 0px;
          }
        }

        .product-quantity {
          width: 15%;
          float: left;

          input {
            width: 50px;
            float: left;
            height: 24px;
          }
        }

        .product-subtotal {
          width: 15%;
          float: left;
          font-weight: $bold;
          font-size: 16px;

          .total_calc {
            width: 100%;
            font-weight: $regular;
            float: left;
            font-size: 14px;
            opacity: 0.8;
          }
        }
      }
    }
  }

  .update_cart {
    float: right;
    background: none;
    border: 0px;
    text-decoration: underline;
    margin-top: 5px;
    font-weight: $regular;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);

    &:focus {
      outline: none;
    }
  }

  .coupon {
    width: 50%;
    float:left;
    margin-top: 20px;

    input[type="text"] {
      border-radius: 3px;
      padding:2px 8px;
      border:1px solid $color-gray;
      float: left;

      &:focus {
        outline: none;
        border:1px solid darken($color-gray, 10);
      }
    }

    .button {
      background: darken($color-primary, 5);
      color: #fff;
      margin:0px 0px 0px 3px;
      border: 0px;
      float: left;

      &:hover {
        background: darken($color-gray, 15);
      }
    }
  }
}

.usp_bar {
  float: right;
  margin-top: -60px;
  
  .col-md-6 {
    padding:0px 5px;
    width: 300px;
    margin-right: 10px;
  }
  .col-md-3 {
    padding:0px 10px;
    width: auto;

    span {
      float: left;
      width: 70px;
      font-weight: $bold;
      line-height: 15px;
    }

    .fa {
      float: left;
      font-size: 28px;
      margin-right: 5px;
      color: $color-secondary;
    }

    img {
      width: 70px;
      height: auto;
      margin-top: -8px;
    }
  }

  .quote {
    width: 100%;
    float: left;
    margin-top: -5px;
    font-weight: $bold;
    line-height: 18px;
  }

  .name {
    width: 100%;
    float: left;
    line-height: 18px;
    color: lighten($color-text, 10);

    .fa {
      color: $color-stars;
    }
  }

  .wc-proceed-to-checkout {
    width: 100%;
    float: left;
  }
}

@include breakpoint(lg) {
  .woocommerce  {
    .custom_cart {
      ul.benefits {
        li {
          img {
            width: 98px;
          }
        }
      }

      .shop_table {
        .cart-items {
          .cart_item {
            .product-name {
              width: 290px;

              a {
                line-height: 21px;
              }
            }
          }
        }
      }
    }
  }
} 

@include breakpoint(md) {
  .usp_bar {
    width: 250px;
    margin-top: -55px;

    .col-md-3 {
      float: right;
    }

    .col-md-6 {
      display: none;
    }
  }

  .woocommerce  {
    .custom_cart {

      table.shop_table {
        margin-bottom: 0px;
      }

      .shipping label {
        padding: 0px 0px 10px 0px;
      }

      .shipping .extra_info {
        margin-top: 0px;
        line-height: 16px;
      }
      
      .cart-collaterals {
        margin-top: 20px;
        padding-bottom: 0px;
      }

      .shop_table {
        .cart-items {
          .cart_item {
            .product-name {
              width: 365px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(sm) {
  .woocommerce-message {
    width: 100%;
    float: left;
    line-height: 24px;

    .button {
      width: 100%;
      float: right;
    }
  }
  .usp_bar {
    display: none;
  }

  .woocommerce .custom_cart {
    .shop_table {
      .cart-items {
        .cart_item {
          .product-name {
            width: calc(70% - 115px);
          }
          .product-subtotal {
            width: 10%;
          }
        }
      }
    }
  }
}

@media (max-width: 620px) {
  .woocommerce .custom_cart {
    
    .cart_totals {
      width: 100%;
      margin-top:20px;

      th, td, tr {
        padding: 0px;
      }
    }

    .coupon {
      width: 100%;
      float: left;

      input {
        width: 100%;
        margin-bottom: 5px;
      }
    }

    ul.benefits {
      li {
        img {
          width: 90px;
        }
      }
    }

    .shop_table {
      
      .cart-head {
        display: none;
      }

      .cart-items {
        .cart_item {  
          padding: 10px 0px;

          &.free-item {
            padding:10px 5px;
          }
          .product-name {
            width: calc(100% - 55px);
            padding-left: 5px;
            margin: 0px;
            font-size: 12px;

            a, h3 {
              margin: 0px;
              line-height: 18px;
              font-size: 14px;
            }
          }
          .product-subtotal {
            float: right;
            width: 55%;
          }

          .product-quantity {
            float: right;
            width: 20%;
            margin-right:10px;

            input {
              width: 100%;
              float: left;
            }
          }

          .product-remove {
            position: absolute;
            right: -10px;
            top: 5px;
          }

          .product-thumbnail {
            width: 50px;
            margin-left: 0px;

            a {
              height: 50px;
            }
          }
        }
      }
    }
  }

}