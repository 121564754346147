/*--------------------------------------------------------------
# Page style
--------------------------------------------------------------*/

.p404 {
	width: 100%;
	text-align: center;
	padding: 80px 0px;

	h1.intro, p.intro {
		width: 100%;
		float: left;
		text-align: center;
	}

	.product_slider {
		width: 100%;
		float: left;
	}

	.navbar-nav {
		margin-left: 20%;
		width: 60%;
		float: left;
		margin-top: 20px;
		margin-bottom: 30px;
		text-align: center;

		li {
			border: 1px solid $color-text;
			float: none;
			display: inline-block;
			margin: 5px;
			color: $color-text;

			&.menu-sale {
				display: none;
			}

			a {
				color: $color-text;
				text-decoration: none;


				&:hover {
					background: $color-text !important;
					color: #fff;
				}
			}
		}
	}
}



/*--------------------------------------------------------------
# Responsive page style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {}

@include breakpoint(sm) {
	
}
