/*--------------------------------------------------------------
# style
--------------------------------------------------------------*/

.woocommerce .product-archive {
  width: 100%;
  float: left;

  .archive-area {
    width: 872px;
    float: right;
    margin-left: 0px;
    position: relative;

    &.brands {
      width: 100%;
    }
  }
  .product-archive-header {
    position: relative;
    width: 100%;
    float: left;
    margin-bottom: 15px;

    &.mobile {
      display: none;
    }

    .product-archive-header-content {
      //background-color: $color-primary;
      color: #333;
      padding: 5px 0px 25px 0px;
      width: 50%;
      float: left;

      a {
        color: #000;
        text-decoration: underline;
      }

      &.full {
        height: auto;
        width: 100%;
        padding: 5px 0px 25px 0px;
      }

      h1 {
        margin: 0px 0px 5px 0px;
        font-size: 24px;
        width: 100%;
        float: left;
      }
      p {
        font-size: 14px;
        line-height: 21px;
      }
    }
    .product-archive-header-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%;
    }
  }

  ul.products li.product {
    padding: 10px;
    margin: -2px -0.2% 10px 0;
    width: 25.15%;
  }

  .product_options {
    width: 100%;
    border-bottom: 1px solid $color-gray;
    float: left;
    padding-bottom: 15px;
    margin-bottom: 20px;
    .woocommerce-result-count,
    .woocommerce-ordering,
    .form-wppp-select {
      float: right;
      margin: 5px 5px;
    }
    .woocommerce-result-count {
      float: left;
    }
    .woocommerce-result-count {
      margin-top: 5px;
    }
    .form-wppp-select {
      select {
        width: 290px;
        position: absolute;
        opacity: 0;
        height: 38px;
        font-size: 14px;
      }
    }
    .customSelect {
      border: 1px solid #dcdcdc;
      background-color: #fff;
      color: #6d797d;
      padding: 5px 45px 5px 10px;
      width: 290px;
      float: right;
      font-size: 14px;
      background: url(../images/select-box-bg.png) right center no-repeat;
      .customSelectInner {
        width: 100% !important;
      }
    }
  }
  .block.product_thumbnail {
    height: 250px;
    .product_image {
      width: 100%;
      height: 192px;
    }
  }
  .archive-footer {
    width: 100%;
    float: left;
    padding: 50px 0;
    p {
      margin-bottom: 0;
    }
    a {
      color: $color-primary;
      text-decoration: underline;
      &:hover,
      &:focus {
        color: darken($color-primary, 10)
      }
    }
  }
  .entry-content {
    margin-top: 20px;
    padding-top: 20px;
    float: left;
    width: 100%;
    border-top: 1px solid #e5e5e5;

    h3, h2, h4, h1 {
      font-size: 21px;
    }
  }
  .woocommerce-pagination {
    text-align: right !important;
    ul {
      border: 0 !important;
      margin-right: -3px !important;
      li {
        border-right: 0 !important;
        border: 1px solid #d3ced2 !important;
        margin: 3px !important;
        span,
        a {
          padding: 14px !important;
          color: $color-primary !important;
          font-weight: 600 !important;
        }
      }
    }
  }
}

.page-template-template-nieuweproducten .product-archive .archive-area {
  width: 100%;
}

.brands .archive-area {
  width: 100%;
}

.woocommerce nav.woocommerce-pagination {
  width: 100%;
  float: left;

  ul {
    li {
      span {
        &.current {
          background: $color-primary;
          color: #fff !important;
        }
      }

      a {
        &:hover {
          background: lighten($color-primary, 50);
        }
      }
    }
  }
}

.entry-content .term-description {
  color: rgba(0, 0, 0, 0.6);

  h2, h3, h4 {
    font-size: 16px !important;
    margin-bottom: 2px;
  }

  p {
    font-size: 13px;
    line-height: 21px;
    margin-bottom: 10px;
  }

  a {
    color: rgba(0, 0, 0, 0.6);
    text-decoration: underline;
  }
}

/*--------------------------------------------------------------
# Responsive style
--------------------------------------------------------------*/

@include breakpoint(lg) {
  .product-archive {

    &.brands {
      .archive-area {
        width: 100%;
      }
    }

    .product-archive-header {
      .product-archive-header-content, .product-archive-header-content.full {
        padding: 20px;
      }
    }
    .archive-area {
      width: calc(100% - 210px);
      right: 0;
      margin-left: 0;

      .woocommerce-result-count {
        display: none;
      }

      .product_options {
        .form-wppp-select, .woocommerce-ordering {
          width: 50%;
          float: left;
          position: relative;

          .customSelect {
            width: 100%;
            float: left;
            left: 0px;
          }
        }

        .form-wppp-select {
          margin-left: 0px !important;
        }

        .woocommerce-ordering {
          margin-right: 0px;
        }
      }
    }
    .entry-content {
      margin-top: 20px;
      padding-top: 10px;
    }
  }
}

@media(max-width: 1200px) {
  .woocommerce .product-archive {
    .archive-area {
      width: 678px;
    }
  }
}

@media(max-width: 991px) {
  .woocommerce .product-archive {
    .archive-area {
      width: 100%;
    }
    .product-archive-header .product-archive-header-content {
      width: 100%;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}

@media(max-width: 768px) {
  .woocommerce .product-archive ul.products li.product {
    width: 49%;
  }
  .product figure {
    margin: 0 0 10px;
  }
}

@media(max-width: 450px) {
  .woocommerce .product-archive ul.products li.product {
    width: 100%;
    padding-bottom: 10px;
  }
}

  @include breakpoint(md) {
  .product-archive {
    .product-archive-header {

      &.mobile {
        display: block;
      }

      .product-archive-header-content, .product-archive-header-content.full {
        padding: 20px;
        width: 100%;
      }

      .product-archive-header-image {
        display: none;
      }
    }
    .archive-area {
      width: 100%;
    }

    .sidebar {
      display: none;
      width: 100%;
    }
  }
}

@include breakpoint(sm) {
  .product-archive .product-archive-header .product-archive-header-content, .product-archive .product-archive-header .product-archive-header-content.full {
    padding: 15px 10px;

    h1 {
      font-size: 21px !important;
    }
  }

  .product-archive {

    .archive-area {

      .product_options {
        .form-wppp-select {
          width: 100% !important;
          float: left !important;
          margin: 0px !important;
        }
        .customSelect, select {
          width: 100% !important;
          float: left;
        }

        .woocommerce-ordering {
          width: 50%;
        }
      }
    }
    .product-archive-header {
      .product-archive-header-content, .product-archive-header-content.full {
        h1 {
          font-size: 30px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media (max-width: 423px){
  .product-archive .archive-area .product_options .woocommerce-ordering {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .woocommerce .product-archive .product-archive-header .product-archive-header-content.full {
    padding: 5px 0 0px;
  }
  .product-archive .archive-area .product_options .woocommerce-ordering{
    margin-left: 0px;
  }
}